import Home from "./components/Home/Home";
import WorldMapView from "./components/WorldMap/WorldMapView";
import CountryComparisonTool from "./components/RankingComparison/CountryComparisonTool";
import CityRankingTool from "./components/RankingComparison/CityRankingTool";
import CityComparisonTool from "./components/RankingComparison/CityComparisonTool";
import Update from "./components/Database/Update";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./css/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import CountryRankingTool from "./components/RankingComparison/CountryRankingTool";
import CustomMenu from "./components/CustomMenu";
import CustomFooter from "./components/CustomFooter";
import CookieBanner from "./components/CookieBanner";
import CountryProfiles from "./components/Ressources/CountryProfiles";
import DiplomaticDirectory from "./components/Ressources/DiplomaticDirectory";
import LawRegulations from "./components/Ressources/LawRegulations";
import GlobalConsularForum from "./components/GlobalConsularForum/GlobalConsularForum";
import AnalysesAndOpinions from "./components/Ressources/AnalysesAndOpinions";
import News from "./components/Ressources/News";
import InterestingLinks from "./components/Ressources/InterestingLinks";
import WeeklyNewsletter from "./components/Ressources/WeeklyNewsletter";
import PrivacyAndTerms from "./components/PrivacyAndTerms/PrivacyAndTerms";
import Partners from "./components/TheCenter/Partners";
import About from "./components/TheCenter/About";
import Donate from "./components/TheCenter/Donate";
import Notice from "./components/TheCenter/Notice";
import Contact from "./components/TheCenter/Contact";
import DidYouKnow from "./components/Ressources/DidYouKnow";
import HonoraryDirectory from "./components/Honorary/HonoraryDirectory";
import HonorarySpaces from "./components/Honorary/HonorarySpaces";
import HonoraryNews from "./components/Honorary/HonoraryNews";
import HonoraryJobs from "./components/Honorary/HonoraryJobs";
import HonoraryCorpsDirectory from "./components/Honorary/HonoraryCorpsDirectory";
import ViennaConventions from "./components/Ressources/ViennaConventions";
import WhatAreConsularAffairs from "./components/Ressources/WhatAreConsularAffairs";
import WhatAreDiplomaticNetworks from "./components/Ressources/WhatAreDiplomaticNetworks";
import ResearchFellows from "./components/TheCenter/ResearchFellows";
import Correspondents from "./components/TheCenter/Correspondents";
import Authors from "./components/Publications/Authors";
import YearlyRankingReport2023 from "./components/Publications/YearlyRankingReport";
import Bibliography from "./components/Ressources/Bibliography";
import Glossary from "./components/Ressources/Glossary";
import Reports from "./components/Publications/Reports";
import HonoraryBibliography from "./components/Honorary/HonoraryBibliography";
import HenryGrahamGreene from "./components/Honorary/HenryGrahamGreene";

function App() {
  return (
    <div className="pagewrap">
      <BrowserRouter>
        <nav>
          <CustomMenu />
        </nav>
        <main className="Site-content">
          <CookieBanner />
          <div style={{ color: "black" }}>
            <Routes>
              <Route path="update" element={<Update />} />
              <Route
                path="country-comparison"
                element={<CountryComparisonTool />}
              />
              <Route path="country-rankings" element={<CountryRankingTool />} />
              <Route path="city-comparison" element={<CityComparisonTool />} />
              <Route path="city-ranking" element={<CityRankingTool />} />
              <Route
                path="1961-and-1963-vienna-conventions"
                element={<ViennaConventions />}
              />

              <Route
                path="2024-ranking-report"
                element={<YearlyRankingReport2023 />}
              />
              <Route path="authors" element={<Authors />} />
              <Route path="reports" element={<Reports />} />

              <Route
                path="who-are-honorary-consuls"
                element={<HonoraryDirectory />}
              />
              <Route
                path="honorary-corps-directory"
                element={<HonoraryCorpsDirectory />}
              />
              <Route
                path="honorary-bibliography"
                element={<HonoraryBibliography />}
              />
              <Route path="honorary-jobs" element={<HonoraryJobs />} />
              <Route path="honorary-news" element={<HonoraryNews />} />
              <Route path="honorary-spaces" element={<HonorarySpaces />} />
              <Route
                path="henry-graham-greene"
                element={<HenryGrahamGreene />}
              />

              <Route
                path="what-are-consular-affairs"
                element={<WhatAreConsularAffairs />}
              />
              <Route
                path="what-are-diplomatic-networks"
                element={<WhatAreDiplomaticNetworks />}
              />
              <Route
                path="analyses-and-opinions"
                element={<AnalysesAndOpinions />}
              />
              <Route path="bibliography" element={<Bibliography />} />
              <Route path="country-profiles" element={<CountryProfiles />} />
              <Route path="daily-press-review" element={<News />} />
              <Route path="did-you-know" element={<DidYouKnow />} />
              <Route
                path="diplomatic-directory"
                element={<DiplomaticDirectory />}
              />
              <Route
                path="global-consular-forum"
                element={<GlobalConsularForum />}
              />
              <Route path="glossary" element={<Glossary />} />
              <Route
                path="laws-regulations-and-policies"
                element={<LawRegulations />}
              />
              <Route path="interesting-links" element={<InterestingLinks />} />
              <Route path="weekly-newsletter" element={<WeeklyNewsletter />} />

              <Route path="about" element={<About />} />
              <Route path="correspondents" element={<Correspondents />} />
              <Route path="research-fellows" element={<ResearchFellows />} />
              <Route path="partners" element={<Partners />} />
              <Route path="notice" element={<Notice />} />
              <Route path="donate" element={<Donate />} />
              <Route path="contact" element={<Contact />} />
              <Route path="privacy-and-terms" element={<PrivacyAndTerms />} />
              <Route exact path="/" element={<Home />} />
            </Routes>
          </div>
        </main>
        <footer>
          <CustomFooter />
        </footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
