import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { AddPageHeader } from "../Common/Utils";

const showResume = (el) => {
  return (
    <div>
      <h3>
        {el.surname !== "" && el.surname + ", "}
        {el.firstname}{" "}
        {el.firstnametranslation !== "" &&
          el.surnametranslation !== "" &&
          " (" + el.surnametranslation + ", " + el.firstnametranslation + ")"}
        {el.firstnametranslation !== "" &&
          el.surnametranslation === "" &&
          " (" + el.firstnametranslation + ")"}
      </h3>
      Correspondent for: {el.state}
      <ul
        style={{
          display: "inline-list-item",
          listStyle: "square",
          listStylePosition: "inside",
        }}
      >
        {el.affiliation1 && <li>{el.affiliation1}</li>}
        {el.affiliation2 && <li>{el.affiliation2}</li>}
      </ul>
    </div>
  );
};

const Correspondents = () => {
  const [team, setCorrespondents] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getCorrespondents().then((data) => setCorrespondents(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("Correspondents")}
      <Divider align="center">
        <h1>Correspondents</h1>
      </Divider>
      {team.map((el) => (
        <div key={el.surname}>
          <Card>
            <div className="grid">
              <div className="flex justify-content-center flex-wrap col-12 md:col-4 lg:col-2">
                <img
                  className="flex align-items-center"
                  src={"images/" + el.visual}
                  alt=""
                  width="150em"
                />
              </div>
              <div
                className="flex align-items-center col-12 md:col-8 lg:col-10"
                style={{ textAlign: "left" }}
              >
                {showResume(el)}
              </div>
            </div>
          </Card>
          <br />
        </div>
      ))}
    </div>
  );
};

export default Correspondents;
