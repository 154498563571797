import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";
import JobsTable from "../Common/JobsTable";
import { CountryService } from "../../services/CountryService";
import { InputText } from "primereact/inputtext";

const HonoraryJobs = () => {
  const [jobs, setJobs] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getJobs().then((data) => setJobs(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("Honorary jobs")}
      <Divider align="center">
        <h1>Honorary jobs</h1>
      </Divider>
      <div className="privacy-terms">
        Are you a governmental representative? Are you looking for an honorary
        consul? Please feel free to{" "}
        <Link
          to="/contact"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          contact
        </Link>{" "}
        the Consular Affairs Center.
        <br />
        <JobsTable data={jobs} searchEnabled longDescription />
      </div>
    </div>
  );
};

export default HonoraryJobs;
