import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { ISOLogoTemplate } from "./ISOCountryLogo";
import { LinkTemplate } from "./SocialNetworkHelpers";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

const JobsTable = ({ data, searchEnabled, longDescription }) => {
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    initFilters1();
  }, []);

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  const renderHeader1 = () => {
    if (searchEnabled) {
      return (
        <div className="flex justify-content-end">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Keyword search"
            />
          </IconField>
        </div>
      );
    }
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const header1 = renderHeader1();

  const NewsOfISOLogoTemplate = (rowData) => {
    return rowData.ofIso[0].toUpperCase();
  };
  const NewsInISOLogoTemplate = (rowData) => {
    return rowData.inIso[0].toUpperCase();
  };

  const OfISOCountryName = (rowData) => {
    return (
      <div>
        {ISOLogoTemplate(rowData.ofIso)} {rowData.ofIsoEn}
      </div>
    );
  };
  const InISOCountryName = (rowData) => {
    return (
      <div>
        {ISOLogoTemplate(rowData.inIso)} {rowData.inIsoEn}
      </div>
    );
  };

  return (
    <DataTable
      value={data}
      responsiveLayout="scroll"
      header={header1}
      filters={filters1}
      filterDisplay="menu"
      paginator={longDescription}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="{first} to {last} of {totalRecords} results"
      rows={10}
      rowsPerPageOptions={[10, 20, 50, 100]}
    >
      <Column field="date" header="Date" sortable></Column>
      {longDescription && (
        <Column field="advertisement" header="Advertisement" sortable></Column>
      )}
      {longDescription && (
        <Column field="deadline" header="Deadline" sortable></Column>
      )}
      {longDescription && (
        <Column header="ISO" body={NewsOfISOLogoTemplate}></Column>
      )}

      <Column
        field="ofIsoEn"
        header="Of country"
        body={OfISOCountryName}
        sortable
      ></Column>

      {longDescription && (
        <Column header="ISO" body={NewsInISOLogoTemplate}></Column>
      )}

      <Column
        field="inIsoEn"
        header="In country"
        body={InISOCountryName}
        sortable
      ></Column>

      {longDescription && <Column field="area" header="Area" sortable></Column>}
      {longDescription && <Column field="city" header="City" sortable></Column>}
      {longDescription && (
        <Column field="website" header="Link" body={LinkTemplate}></Column>
      )}
    </DataTable>
  );
};

export default JobsTable;
