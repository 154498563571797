import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import LawRegulationsTable from "../Common/LawRegulationsTable";
import { AddPageHeader } from "../Common/Utils";

const LawRegulations = () => {
  const [laws, setLaws] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getLaws().then((data) => setLaws(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("Laws, regulations, and policies")}
      <Divider align="center">
        <h1>Laws, regulations, and policies</h1>
      </Divider>
      <div className="privacy-terms">
        Are there any laws, regulations, or policies that you would like to
        highlight? Please feel free to{" "}
        <Link
          to="/contact"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          contact
        </Link>{" "}
        the Consular Affairs Center to have these texts documented.
      </div>
      <LawRegulationsTable data={laws} searchEnabled longDescription />
    </div>
  );
};

export default LawRegulations;
