import { Image } from "primereact/image";
import "primeicons/primeicons.css";

export const MySocialNetworks = () => {
  return (
    <div className="flex justify-content-center mt-2">
      <a
        href="https://www.facebook.com/consularaff"
        target="_blank"
        style={{ color: "black", margin: "0 0.5rem" }}
      >
        <i className="pi pi-facebook"></i>
      </a>

      <a
        href="https://www.instagram.com/consularaff"
        target="_blank"
        style={{ color: "black", margin: "0 0.5rem" }}
      >
        <i className="pi pi-instagram"></i>
      </a>

      <a
        href="https://pf.kakao.com/_LqVxas"
        target="_blank"
        style={{ margin: "0 0.5rem", color: "black" }}
      >
        <Image src="images/kakao-logo.svg" alt="kakaotalk" width="16" />
      </a>

      <a
        href="https://page.line.me/119urefi"
        target="_blank"
        style={{ margin: "0 0.5rem", color: "black" }}
      >
        <Image src="images/line-logo.png" alt="line" width="16" />
      </a>

      <a
        href="https://www.linkedin.com/company/consularaffairs"
        target="_blank"
        style={{ color: "black", margin: "0 0.5rem" }}
      >
        <i className="pi pi-linkedin"></i>
      </a>

      <a
        href="https://t.me/consularaff"
        target="_blank"
        style={{ margin: "0 0.5rem", color: "black" }}
      >
        <i className="pi pi-telegram"></i>
      </a>

      <a
        href="https://www.threads.net/@consularaff"
        target="_blank"
        style={{ margin: "0 0.5rem", color: "black" }}
      >
        <Image src="images/threads-logo.svg" alt="threads" width="16" />
      </a>

      <a
        href="https://invite.viber.com/?g2=AQB7rSDIpkQ9TFL5gf4PBrkj9WFggZlgngLkaqVh5jnjQfusXTT2z2ORfy%2FQYsm5"
        target="_blank"
        style={{ margin: "0 0.5rem", color: "black" }}
      >
        <Image src="images/viber-logo.svg" alt="viber" width="16" />
      </a>

      <a
        href="https://vk.com/consularaffairs"
        target="_blank"
        style={{ margin: "0 0.5rem", color: "black" }}
      >
        <Image src="images/vk-logo.svg" alt="vk" width="16" />
      </a>

      <a
        href="https://www.whatsapp.com/channel/0029Va9DnKvC6Zvia6uwnu3q"
        target="_blank"
        style={{ color: "black", margin: "0 0.5rem" }}
      >
        <i className="pi pi-whatsapp"></i>
      </a>

      <a
        href="https://x.com/consularaff"
        target="_blank"
        style={{ color: "black", margin: "0 0.5rem" }}
      >
        <Image src="images/x-logo.svg" alt="x" width="16" />
      </a>
    </div>
  );
};
