import { Helmet } from "react-helmet";
import { ConsularAffairsTitle } from "./Constants";

export const AddPageHeader = (title) => {
  return (
    <Helmet>
      <title>{`${title} | ${ConsularAffairsTitle}`}</title>
    </Helmet>
  );
};

export const AddHomePageHeader = () => {
  return (
    <Helmet>
      <title>{`${ConsularAffairsTitle}`}</title>
    </Helmet>
  );
};
