import React, { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { CountryService } from "../../services/CountryService";
import { AddPageHeader } from "../Common/Utils";

const Contact = () => {
  let navigate = useNavigate();

  const toast = useRef(null);
  const defaultValues = {
    name: "",
    email: "",
    subject: "",
    description: "",
    accept: false,
  };

  const countryService = new CountryService();

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Message sent.",
      detail: "Thank you.",
    });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    countryService.sendContactForm(data);
    showSuccess();
    setTimeout(() => {
      navigate("/");
    }, 2000);

    reset();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div>
      {AddPageHeader("Contact")}
      <Toast ref={toast} position="top-center" />

      <div className="reduce-width">
        <Divider align="center">
          <h1>Contact</h1>
        </Divider>
        <div className="mx-auto">contact (at) consularaffairs (dot) org</div>

        <div className="flex justify-content-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-fluid mt-2 w-full md:w-8 "
            style={{ textAlign: "left" }}
          >
            <Divider align="center">
              <b>or</b>
            </Divider>
            <div className="bottom-margin">
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="name"
                  className={classNames({ "p-error": errors.name })}
                >
                  Name
                </label>
              </span>
              {getFormErrorMessage("name")}
            </div>
            <div className="bottom-margin">
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-envelope" />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address format.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="email"
                  className={classNames({ "p-error": !!errors.email })}
                >
                  Email
                </label>
              </span>
              {getFormErrorMessage("email")}
            </div>
            <div className="bottom-margin">
              <span className="p-float-label">
                <Controller
                  name="subject"
                  control={control}
                  rules={{ required: "Subject is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="subject"
                  className={classNames({ "p-error": errors.name })}
                >
                  Subject*
                </label>
              </span>
              {getFormErrorMessage("subject")}
            </div>
            <div>
              <span className="p-float-label">
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: "Description is required." }}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={5}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="description"
                  className={classNames({ "p-error": errors.name })}
                >
                  Description*
                </label>
              </span>
              {getFormErrorMessage("description")}
            </div>
            <div className="flex justify-content-center">
              <Button
                type="submit"
                label="Send"
                className="mt-2 w-full md:w-5"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
