import React from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

const WhatAreConsularAffairs = () => {
  return (
    <div>
      {AddPageHeader("What are consular affairs?")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>What are consular affairs?</h1>
        </Divider>
        <div className="privacy-terms">
          <p>
            As per the 1963 Vienna Convention on Consular Relations, the
            consular functions consist in:
          </p>
          <br />
          <p>
            (a) protecting in the receiving State the interests of the sending
            State and of its nationals, both individuals and bodies corporate,
            within the limits permitted by international law;
          </p>
          <br />
          <p>
            (b) furthering the development of commercial, economic, cultural and
            scientific relations between the sending State and the receiving
            State and otherwise promoting friendly relations between them in
            accordance with the provisions of the present Convention;
          </p>
          <br />
          <p>
            (c) ascertaining by all lawful means conditions and developments in
            the commercial, economic, cultural and scientific life of the
            receiving State, reporting thereon to the Government of the sending
            State and giving information to persons interested;
          </p>
          <br />
          <p>
            (d) issuing passports and travel documents to nationals of the
            sending State, and visas or appropriate documents to persons wishing
            to travel to the sending State;
          </p>
          <br />
          <p>
            (e) helping and assisting nationals, both individuals and bodies
            corporate, of the sending State;
          </p>
          <br />
          <p>
            (f) acting as notary and civil registrar and in capacities of a
            similar kind, and performing certain functions of an administrative
            nature, provided that there is nothing contrary thereto in the laws
            and regulations of the receiving State;
          </p>
          <br />
          <p>
            (g) safeguarding the interests of nationals, both individuals and
            bodies corporate, of the sending States in cases of succession
            mortis causa in the territory of the receiving State, in accordance
            with the laws and regulations of the receiving State;
          </p>
          <br />
          <p>
            (h) safeguarding, within the limits imposed by the laws and
            regulations of the receiving State, the interests of minors and
            other persons lacking full capacity who are nationals of the sending
            State, particularly where any guardianship or trusteeship is
            required with respect to such persons;
          </p>
          <br />
          <p>
            (i) subject to the practices and procedures obtaining in the
            receiving State, representing or arranging appropriate
            representation for nationals of the sending State before the
            tribunals and other authorities of the receiving State, for the
            purpose of obtaining, in accordance with the laws and regulations of
            the receiving State, provisional measures for the preservation of
            the rights and interests of these nationals, where, because of
            absence or any other reason, such nationals are unable at the proper
            time to assume the defence of their rights and interests;
          </p>
          <br />
          <p>
            (j) transmitting judicial and extrajudicial documents or executing
            letters rogatory or commissions to take evidence for the courts of
            the sending State in accordance with international agreements in
            force or, in the absence of such international agreements, in any
            other manner compatible with the laws and regulations of the
            receiving State;
          </p>
          <br />
          <p>
            (k) exercising rights of supervision and inspection provided for in
            the laws and regulations of the sending State in respect of vessels
            having the nationality of the sending State, and of aircraft
            registered in that State, and in respect of their crews;
          </p>
          <br />
          <p>
            (l) extending assistance to vessels and aircraft mentioned in
            subparagraph (k) of this article, and to their crews, taking
            statements regarding the voyage of a vessel, examining and stamping
            the ship's papers, and, without prejudice to the powers of the
            authorities of the receiving State, conducting investigations into
            any incidents which occurred during the voyage, and settling
            disputes of any kind between the master, the officers and the seamen
            insofar as this may be authorized by the laws and regulations of the
            sending State;
          </p>
          <br />
          <p>
            (m) performing any other functions entrusted to a consular post by
            the sending State which are not prohibited by the laws and
            regulations of the receiving State or to which no objection is taken
            by the receiving State or which are referred to in the international
            agreements in force between the sending State and the receiving
            State.
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};

export default WhatAreConsularAffairs;
