import React, { useState } from "react";
import { ListBox } from "primereact/listbox";

const countryOptionTemplate = (option) => {
  return (
    <div style={{ display: "flex" }}>
      <img
        src={"flags/" + option.code.toLowerCase() + ".svg"}
        alt=""
        width="17em"
        style={{ marginRight: "0.7em" }}
      />
      {option.name}
    </div>
  );
};

const CountrySearchStatistics = ({ countries, action }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
    action(e);
  };

  return (
    <div className="map-list-box">
      <ListBox
        value={selectedCountry}
        options={countries}
        onChange={onCountryChange}
        optionLabel="name"
        filter
        itemTemplate={countryOptionTemplate}
        style={{ width: "21.8rem" }}
        listStyle={{ maxHeight: "7rem" }}
      />
    </div>
  );
};

export default CountrySearchStatistics;
