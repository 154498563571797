import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";
import MailChimp from "./MailChimp";

const WeeklyNewsletter = () => {
  return (
    <div>
      {AddPageHeader("Weekly newsletter")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>Weekly newsletter</h1>
        </Divider>
        <div className="privacy-terms">
          <p>
            The weekly newsletter compiles the latest major news on consular
            affairs and diplomatic networks from both global and local press
            sources, along with bibliographic extracts. You will receive the
            newsletter via email at the beginning of each week. Keep informed!
            <br />
            <br />
            <MailChimp />
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeeklyNewsletter;
