import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";

const LastUpdate = () => {
  const [info, setInfo] = useState(null);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getInfo().then((data) => setInfo(data));
  }, []);

  return <div>Last update: {info && info.lastupdate}.</div>;
};

export default LastUpdate;
