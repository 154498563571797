import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

const HonorarySpaces = () => {
  return (
    <div className="reduce-width">
      {AddPageHeader("Honorary spaces")}
      <Divider align="center">
        <h1>Honorary spaces</h1>
      </Divider>
      <div className="privacy-terms">
        The Consular Affairs Center builds and uphold online spaces for consular
        corps and honorary consuls. Please feel free to{" "}
        <Link
          to="/contact"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          contact
        </Link>{" "}
        the Center for further information.
      </div>
    </div>
  );
};

export default HonorarySpaces;
