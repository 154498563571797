import React, { Component } from "react";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
import { AddPageHeader } from "../Common/Utils";

class AnalysesAndOpinions extends Component {
  render() {
    return (
      <div>
        {AddPageHeader("Analyses & Opinions")}
        <div className="reduce-width">
          <Divider align="center">
            <h1>Analyses & Opinions</h1>
          </Divider>
          <div className="privacy-terms">
            Would you like to share your insights? Please{" "}
            <Link
              to="/contact"
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              contact
            </Link>{" "}
            the Consular Affairs Center.
          </div>
        </div>
      </div>
    );
  }
}

export default AnalysesAndOpinions;
