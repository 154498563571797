import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { LinkTemplate } from "./SocialNetworkHelpers";
import { ISOLogoTemplate } from "./ISOCountryLogoList";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

const NewsTable = ({ data, searchEnabled, longDescription }) => {
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    initFilters1();
  }, []);

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  const renderHeader1 = () => {
    if (searchEnabled) {
      return (
        <div className="flex justify-content-end">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Keyword search"
            />
          </IconField>
        </div>
      );
    }
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const header1 = renderHeader1();

  const NewsISOLogoTemplate = (rowData) => {
    return <div>{ISOLogoTemplate(rowData.iso)} </div>;
  };

  return (
    <DataTable
      value={data}
      responsiveLayout="scroll"
      header={header1}
      filters={filters1}
      filterDisplay="menu"
      paginator={longDescription}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="{first} to {last} of {totalRecords} results"
      rows={10}
      rowsPerPageOptions={[10, 20, 50, 100]}
    >
      <Column field="date" header="Date" sortable></Column>
      <Column field="title" header="Title" sortable></Column>
      <Column field="source" header="Source" sortable></Column>
      {longDescription && (
        <Column field="link" header="Link" body={LinkTemplate}></Column>
      )}
      {longDescription && (
        <Column
          field="iso"
          header="Country"
          body={NewsISOLogoTemplate}
        ></Column>
      )}
    </DataTable>
  );
};

export default NewsTable;
