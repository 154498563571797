import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import OfficialDirectoryTable from "../Common/OfficialDirectoryTable";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";
import { Link } from "react-router-dom";

const DiplomaticDirectory = () => {
  const [officialDirectory, setOfficialDirectory] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService
      .getOfficialDirectory()
      .then((data) => setOfficialDirectory(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("Diplomatic directory")}
      <Divider align="center">
        <h1>Diplomatic directory</h1>
      </Divider>
      <div className="privacy-terms">
        The diplomatic directory consolidates official public sources of
        information on countries' bodies responsible for foreign affairs. Are
        you a governmental representative? Would you like the directory to be
        updated? Please feel free to{" "}
        <Link
          to="/contact"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          contact
        </Link>{" "}
        the Consular Affairs Center.
      </div>
      <OfficialDirectoryTable
        data={officialDirectory}
        searchEnabled
        longDescription
      />
    </div>
  );
};

export default DiplomaticDirectory;
