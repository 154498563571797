const buildSocialNetwork = (link: string, icon: string) => {
  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        style={{ color: "black", margin: "0 0.5rem" }}
      >
        <i className={icon}></i>
      </a>
    );
  }
};

const buildCustomSocialNetwork = (link: string, name: string) => {
  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        style={{ color: "black", margin: "0.1rem 0.5rem 0 0.5rem" }}
      >
        <img src={`images/${name}-logo.svg`} width="18em" />
      </a>
    );
  }
};

export const SocialNetworksTemplate = (data: any) => {
  const networks = data.networks;
  if (networks) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {buildSocialNetwork(networks.blockdit, "pi pi-link")}
        {buildSocialNetwork(networks.facebook1, "pi pi-facebook")}
        {buildSocialNetwork(networks.facebook2, "pi pi-facebook")}
        {buildSocialNetwork(networks.facebook3, "pi pi-facebook")}
        {buildCustomSocialNetwork(networks.flickr, "flickr")}
        {buildSocialNetwork(networks.instagram, "pi pi-instagram")}
        {buildSocialNetwork(networks.instagram2, "pi pi-instagram")}
        {buildCustomSocialNetwork(networks.kakaotalk, "kakao")}
        {buildSocialNetwork(networks.line, "pi pi-link")}
        {buildSocialNetwork(networks.linkedin, "pi pi-linkedin")}
        {buildSocialNetwork(networks.linkedin2, "pi pi-linkedin")}
        {buildCustomSocialNetwork(networks.linktree, "linktree")}
        {buildCustomSocialNetwork(networks.naver, "naver")}
        {buildSocialNetwork(networks.ok, "pi pi-link")}
        {buildCustomSocialNetwork(networks.periscope1, "periscope")}
        {buildCustomSocialNetwork(networks.rutube, "rutube")}
        {buildCustomSocialNetwork(networks.snapchat, "snapchat")}
        {buildCustomSocialNetwork(networks.soundcloud, "soundcloud")}
        {buildCustomSocialNetwork(networks.spotify, "spotify")}
        {buildCustomSocialNetwork(networks.spreaker, "spreaker")}
        {buildSocialNetwork(networks.telegram, "pi pi-telegram")}
        {buildCustomSocialNetwork(networks.threads, "threads")}
        {buildCustomSocialNetwork(networks.tiktok1, "tiktok")}
        {buildCustomSocialNetwork(networks.viber, "viber")}
        {buildCustomSocialNetwork(networks.viber2, "viber")}
        {buildSocialNetwork(networks.virasty, "virasty")}
        {buildCustomSocialNetwork(networks.vk, "vk")}
        {buildCustomSocialNetwork(networks.wechat, "wechat")}
        {buildCustomSocialNetwork(networks.weibo, "weibo")}
        {buildCustomSocialNetwork(networks.twitter1, "x")}
        {buildCustomSocialNetwork(networks.twitter2, "x")}
        {buildCustomSocialNetwork(networks.twitter3, "x")}
        {buildCustomSocialNetwork(networks.twitter4, "x")}
        {buildCustomSocialNetwork(networks.twitter5, "x")}
        {buildCustomSocialNetwork(networks.twitter6, "x")}
        {buildSocialNetwork(networks.youtube1, "pi pi-youtube")}
        {buildSocialNetwork(networks.youtube2, "pi pi-youtube")}
      </div>
    );
  }
};

export const LinkTemplate = (data: any) => {
  return (
    <a
      href={data.website}
      target="_blank"
      style={{ color: "black", marginRight: "1rem" }}
    >
      <i className="pi pi-link"></i>
    </a>
  );
};
