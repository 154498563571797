import React from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

const PrivacyAndTerms = () => {
  return (
    <div>
      {AddPageHeader("Privacy and terms")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>Privacy and terms</h1>
        </Divider>
        <div className="privacy-terms">
          <p>Dernière mise à jour : 01 mai 2024</p>
          <br />
          <h3>A. Introduction</h3>
          <br />
          <p>
            1. L’objectif de cette page est de faciliter la compréhension des
            conditions générales d’utilisation et de la gestion de la
            confidentialité pour le Visiteur du Site Internet, en application
            des lois et règlements applicables.
          </p>
          <br />
          <p>
            2. L’utilisation du Site Internet implique l’acceptation pleine et
            entière des dispositions ci-après décrites. Celles-ci sont
            susceptibles d’être modifiées à tout moment : le Visiteur est donc
            invité à les consulter de manière régulière.
          </p>
          <br />
          <h3>B. Définitions</h3>
          <br />
          <p>3. Les termes suivants sont définis :</p>
          <p>
            Contenu : Ensemble des éléments et informations présent sur le Site
            Internet.
          </p>
          <p>
            Donnée(s) Personnelle(s) : Information(s) permettant d'identifier
            une personne physique.
          </p>
          <p>Site Internet : Le présent site internet.</p>
          <p>
            Visiteur(s) : Internaute(s) se connectant et utilisant le Site
            Internet.
          </p>
          <br />
          <h3>C. Description</h3>
          <br />
          <p>
            4. Le Site Internet est un site internet non gouvernemental ayant
            pour principal objet de fournir des informations sur les affaires
            consulaires et réseaux diplomatiques au sens large. Le Site Internet
            souhaite ainsi venir en aide aux Visiteurs ayant un besoin
            d’informations.
          </p>
          <br />
          <h3>D. Limitation de responsabilité</h3>
          <br />
          <p>
            5. Le Site Internet ne pourra être tenu responsable de dommages
            directs ou indirects, matériels ou immatériels, liés à la connexion
            et à l’utilisation du Site Internet et de son Contenu.
          </p>
          <p>
            6. Le Site Internet s’efforce de fournir un Contenu aussi précis et
            adéquat que possible. Toutefois, il ne pourra être tenu responsable
            d’inexactitudes. Toutes les informations proposées sur le Site
            Internet sont données à titre indicatif.
          </p>
          <p>
            7. Le Site Internet contient des liens hypertextes vers d’autres
            sites internet : il est apporté à la connaissance du Visiteur que le
            contenu de ces sites est susceptible de varier de manière continue,
            sans contrôle possible, et que le Site Internet ne pourra être tenu
            responsable de la connexion à ces sites et à leur consultation.
          </p>
          <p>
            8. Le Site Internet contient des liens hypertextes vers d’autres
            sites internet : il est apporté à la connaissance du Visiteur que le
            contenu de ces sites est susceptible de varier de manière continue,
            sans contrôle possible, et que le Site Internet ne pourra être tenu
            responsable de la connexion à ces sites et à leur consultation. Le
            Visiteur s’engage à accéder au Site Internet en utilisant un
            matériel récent, ne contenant pas de virus, et avec un navigateur de
            dernière génération mis à jour. L’utilisation d’un logiciel de
            contrôle parental est préconisée, spécialement en cas de
            consultation de liens hypertextes. Le Site Internet ne pourra être
            tenu responsable des dommages causés au matériel de l’utilisateur,
            résultant soit de l’utilisation d’un matériel ne répondant pas aux
            spécifications susnommées, soit de l’apparition d’un bug ou d’une
            incompatibilité.
          </p>
          <p>
            9. Le Site Internet est hébergé chez un prestataire sur le
            territoire de l’Union Européenne. L’objectif est d’optimiser la
            sécurité et l’accessibilité. Le Site Internet et/ou l’hébergeur se
            réservent néanmoins la possibilité d’interrompre le service
            d’hébergement, notamment en cas de maintenance, d’amélioration des
            infrastructures, de défaillance des infrastructures, ou de trafic
            réputé anormal. Également, le Site Internet et l’hébergeur ne
            pourront être tenus responsables en cas de dysfonctionnement du
            réseau Internet, des lignes téléphoniques, ou du matériel
            informatique et de téléphonie.
          </p>
          <p>
            10. Il est apporté à la connaissance du Visiteur que, quels que
            soient les efforts fournis, aucune méthode de transmission sur
            Internet et aucune méthode de stockage électronique n'est
            complètement sûre. Nous ne pouvons en conséquence pas garantir une
            sécurité absolue.
          </p>
          <br />
          <h3>E. Propriété intellectuelle</h3>
          <br />
          <p>
            11. Le Site Internet constitue une œuvre de l’esprit protégée par
            les dispositions des lois et règlements nationaux et internationaux
            applicables. Toute utilisation, reproduction, représentation,
            modification, publication, adaptation de tout ou partie des
            informations du Site Internet crées par le ou les propriétaires du
            Site Internet (e.g., graphismes, icônes, images, logos, procédés,
            sons, textes, et vidéos applicables), quel que soit le moyen ou le
            procédé utilisé, est interdite sans autorisation expresse. Toute
            exploitation non autorisée du Site Internet ou de l’un des éléments
            applicables qu’il contient, également possiblement considérée comme
            constitutive d’une contrefaçon, sera susceptible d’être poursuivie.
          </p>
          <p>
            12. es liens vers le présent Site Internet sont autorisés dans la
            mesure où ils ne portent pas préjudice à son image. Toute citation
            ou utilisation du présent site devra faire figurer le nom du site et
            son URL. Est interdite toute technique ou pratique susceptible
            d’engendrer une confusion dans l’esprit du public, notamment quant à
            l’origine de l’information.
          </p>
          <p>
            13. Le Site Internet s’efforce de ne pas utiliser une propriété
            intellectuelle tierce non libre de droits. Dans le cas contraire, le
            Site Internet s’efforce de mentionner les propriétaires tiers. Tout
            Visiteur est invité à utiliser le formulaire de contact à des fins
            de signalement.
          </p>
          <br />
          <h3>F. Informations</h3>
          <br />
          <p>
            14. Les contacts du Site Internet sont les suivants, également
            accessibles via le formulaire de contact : Délégué à la protection
            des données, éditeur, hébergeur, propriétaire, et webmaster : 2, Rue
            Kellermann, 59100 Roubaix, France - 1007.
          </p>
          <p>
            15. Le Site Internet se réserve la possibilité de mettre en cause la
            responsabilité civile et/ou pénale du Visiteur, notamment en cas de
            message à caractère raciste, injurieux, diffamant, ou
            pornographique, quel que soit le support utilisé (e.g., photographie
            ou texte).
          </p>
          <p>
            16. Pour l’information du Visiteur, la CNIL propose un portail avec
            des conseils et informations liés, en particulier sur le Règlement
            Général de Protection des Données (RGPD).
          </p>
          <p>
            17. Tout litige en relation avec l’utilisation du Site Internet est
            soumis au droit français.
          </p>
          <br />
          <h3>G. Données Personnelles, cookies, et informations liées</h3>
          <br />
          <p>18. Le Site Internet ne recueille pas de Données Personnelles.</p>
          <p>
            19. En cas d’utilisation du formulaire de contact, le Visiteur est
            enjoint à ne pas saisir de Données Personnelles. Les informations
            recueillies sont conservées sur une boite de réception email
            sécurisée, entre autres, par un mot de passe. Les terminaux où ces
            données sont accessibles (e.g., ordinateur) sont protégés, entre
            autres, par un mot de passe. En cas de réception de Données
            Personnelles, toutes les mesures raisonnables pour s’assurer de leur
            confidentialité seront prises. Le Visiteur dispose d’un droit
            d’accès, de rectification, et d’opposition aux Données Personnelles
            le concernant en contactant le Délégué à la protection des données
            du Site Internet qui supprimera ou modifiera les données afférentes.
          </p>
          <p>
            20. La navigation sur le Site Internet est susceptible de provoquer
            l’installation de cookie(s) sur le terminal du Visiteur. Un cookie
            est un fichier de petite taille, qui ne permet pas l’identification
            du Visiteur, mais qui enregistre des informations relatives à la
            navigation d’un ordinateur sur un site. De manière automatique,
            certaines informations concernant le Visiteur lors d’une simple
            navigation sur le Site Internet sont susceptibles d’être collectées,
            notamment : le fournisseur d’accès, l’URL des liens par
            l’intermédiaire desquels le Visiteur a accédé au Site Internet, les
            pages consultées, l’adresse de protocole Internet, le type de
            navigateur, ou le temps d’accès. Ces informations sont utilisées,
            par exemple, à des fins de statistiques et de sécurité du Site
            Internet, pour améliorer la qualité et le Contenu du Site Internet
            ainsi que la navigation du Visiteur. Également, en cliquant sur
            certain(e)s icônes ou liens hypertextes (e.g., Facebook ou LinkedIn)
            figurant sur le Site Internet, l’installation de cookies sur les
            terminaux du Visiteur est susceptible de se poursuivre.
          </p>
          <p>
            21. Le Site Internet utilise des solutions de prestataires tiers,
            présentant des garanties au regard des exigences applicables, qui
            nécessitent l’utilisation de cookies récoltant les informations
            susnommées. Comme outil de statistiques, ce site utilise notamment
            Google Analytics : " Ce site utilise Google Analytics, un service
            d’analyse de site internet fourni par Google Inc. (" Google ").
            Google Analytics utilise des cookies qui sont des fichiers texte
            placés sur votre ordinateur afin d'aider le site Web à analyser la
            manière dont les utilisateurs font usage du site. Les informations
            générées par le cookie concernant votre utilisation du site Web (y
            compris votre adresse IP) sera transmise et stockée par Google sur
            ses serveurs aux États-Unis. Google utilisera ces informations aux
            fins d'évaluer votre utilisation du site Web, de compiler des
            rapports sur l'activité du site Web à destination des exploitants du
            site Web et de fournir d'autres services se rapportant à l'activité
            du site Web et à l'usage d'Internet. Google pourra également
            communiquer ces informations à des tiers lorsque cela est requis par
            la loi ou lorsque ces tiers traitent les informations pour le compte
            de Google. Google, s'engage à ne pas associer votre adresse IP à
            d'autres données détenues par Google. Vous avez la possibilité de
            refuser l'usage de cookies en sélectionnant les consignes requises
            sur votre navigateur ; veuillez cependant noter que si vous agissez
            de la sorte, il est possible que vous ne puissiez plus utiliser la
            totalité des fonctionnalités de ce site Web. En utilisant ce site
            Web, vous acceptez que Google traite des données vous concernant de
            la manière et aux fins décrites ci-dessus."
          </p>
          <p>
            22. Le Visiteur peut configurer son terminal (e.g., appareil mobile
            ou ordinateur) pour refuser l’installation des cookies. Par exemple,
            les navigateurs Chrome, Edge, Firefox, Internet Explorer, et Safari
            proposent de désactiver les cookies. Cette option est généralement
            accessible via les onglets options, outils, ou paramètres internet
            puis confidentialité, ou vie privée. Le Visiteur est encouragé à
            consulter régulièrement les aides et FAQ proposées par les éditeurs
            des navigateurs susnommés.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndTerms;
