import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const CityRankingTable = ({ data, longDescription }) => {
  const countryTemplate = (rowData) => {
    return (
      <div style={{ display: "flex" }}>
        <img
          src={"flags/" + rowData.countrycode.toLowerCase() + ".svg"}
          alt=""
          width="17em"
          style={{ marginRight: "0.7em" }}
        />
        {rowData.countryname}
      </div>
    );
  };

  const isoTemplate = (rowData) => {
    return <div>{rowData.countrycode.toUpperCase()}</div>;
  };

  const missionTemplate = (rowData) => {
    return rowData.missions;
  };

  const embassyRankingTemplate = (rowData) => {
    if (rowData.embassies === 0) {
      return "N/A";
    }
    return rowData.embassiesranking;
  };

  return (
    <DataTable
      value={data}
      responsiveLayout="scroll"
      paginator={longDescription}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="{first} to {last} of {totalRecords} results"
      rows={10}
      rowsPerPageOptions={[10, 20, 50, 100]}
    >
      <Column field="code" header="ISO" body={isoTemplate} sortable></Column>
      <Column
        field="country"
        header="Country"
        body={countryTemplate}
        sortable
      ></Column>
      <Column field="name" header="City" sortable></Column>
      <Column
        field="embassies"
        header="Embassies and high commissions"
        sortable
      ></Column>
      <Column field="consulates" header="Consulates" sortable></Column>
      <Column field="others" header="Other posts" sortable></Column>
      <Column field="totalposts" header="Total posts" sortable></Column>
      <Column
        field="embassiesranking"
        header="Ranking (embassies and high commissions)"
        body={embassyRankingTemplate}
        sortable
      ></Column>
      <Column
        field="totalpostsranking"
        header="Ranking (total posts)"
        sortable
      ></Column>
      <Column
        field="missions"
        header="Permanent missions"
        body={missionTemplate}
        sortable
      ></Column>
      {/* <Column
        field="othersnorelations"
        header="Other representations from territories with no formal diplomatic relations"
        sortable
      ></Column> */}
    </DataTable>
  );
};

export default CityRankingTable;
