import React, { useState, useEffect } from "react";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { CountryService } from "../../services/CountryService";
import { AddPageHeader } from "../Common/Utils";

const InterestingLinks = () => {
  const [links, setLinks] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getLinks().then((data) => setLinks(data));
  }, []);

  return (
    <div>
      {AddPageHeader("Interesting links")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>Interesting links</h1>
        </Divider>
        {links.map((el) => (
          <div key={el.link}>
            <Card>
              <div className="grid">
                <div className="flex align-content-center flex-wrap col-12 md:col-4 lg:col-2">
                  <img
                    className="flex align-items-center"
                    src={"images/" + el.picture}
                    alt=""
                    width="200em"
                  />
                </div>
                <div
                  className="col-12 md:col-8 lg:col-10"
                  style={{ textAlign: "left" }}
                >
                  <h3>{el.title}</h3>
                  <br />
                  <p>{el.description}</p>
                  <br />
                  <p>
                    Website:{" "}
                    <a
                      href={el.link}
                      target="_blank"
                      style={{ color: "black", marginRight: "1rem" }}
                    >
                      {el.link}
                    </a>
                  </p>
                </div>
              </div>
            </Card>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InterestingLinks;
