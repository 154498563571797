import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { AddPageHeader } from "../Common/Utils";
import { SocialNetworksTemplate } from "../Common/SocialNetworkHelpers";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getPartners().then((data) => setPartners(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("Partners")}
      <Divider align="center">
        <h1>Partners</h1>
      </Divider>
      {partners.map((el) => (
        <div key={el.link}>
          <Card>
            <div className="grid">
              <div className="flex justify-content-center flex-wrap col-12 md:col-4 lg:col-3">
                <img
                  className="flex align-items-center"
                  src={"images/" + el.visual}
                  alt=""
                  width="200em"
                />
              </div>
              <div
                className="col-12 md:col-8 lg:col-9"
                style={{ textAlign: "left" }}
              >
                <h3>{el.organization}</h3>
                {el.category}
                <ul
                  style={{
                    display: "inline-list-item",
                    listStyle: "square",
                    listStylePosition: "inside",
                  }}
                >
                  <li>Country: {el.country}</li>
                  <li>
                    Website:{" "}
                    <a
                      href={el.website}
                      target="_blank"
                      style={{ color: "black", marginRight: "1rem" }}
                    >
                      {el.website}
                    </a>
                  </li>
                </ul>
                <br />
                {SocialNetworksTemplate(el)}
              </div>
            </div>
            <br />
            <div className="privacy-terms">{el.description}</div>
          </Card>
          <br />
        </div>
      ))}
    </div>
  );
};

export default Partners;
