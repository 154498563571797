import React from "react";
import CookieConsent from "react-cookie-consent";

function CookieBanner() {
  return (
    <CookieConsent
      style={{ background: "#000000" }}
      buttonStyle={{
        color: "black",
        background: "#FFFFFF",
        borderRadius: "0.255rem",
        fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
        fontSize: "13px",
      }}
      buttonText="OK"
      expires={150}
    >
      This website uses cookies to improve your experience. Please refer to the
      privacy policy for more details.
    </CookieConsent>
  );
}

export default CookieBanner;
