import React from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

const WhatAreDiplomaticNetworks = () => {
  return (
    <div>
      {AddPageHeader("What are diplomatic networks?")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>What are diplomatic networks?</h1>
        </Divider>
        <div className="privacy-terms">
          <p>
            A diplomatic network can be defined as the ensemble of diplomatic
            representations – or "posts" – maintained by a state in the world.
          </p>
          <br />
          <p>
            Alternatively, it can be defined as the connection of at least two
            computer systems serving a diplomatic purpose.
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};

export default WhatAreDiplomaticNetworks;
