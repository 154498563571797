import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";
import GlossaryTable from "../Common/GlossaryTable";

const Glossary = () => {
  const [glossary, setGlossary] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getGlossary().then((data) => setGlossary(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("Glossary")}
      <Divider align="center">
        <h1>Glossary</h1>
      </Divider>
      <div className="privacy-terms">
        The glossary excerpts dictionary entries sourced from Cambridge
        University Press & Assessment (CUPA), Merriam-Webster (MW), and Oxford
        University Press (OUP). The Consular Affairs Center invites readers to
        directly consult the educational materials provided by the three
        organizations.
      </div>
      <GlossaryTable data={glossary} searchEnabled longDescription />
    </div>
  );
};

export default Glossary;
