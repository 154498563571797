import { Divider } from "primereact/divider";
import { Image } from "primereact/image";
import { AddPageHeader } from "../Common/Utils";
import logo from "../../assets/images/Logo-GlobalConsularForum.png";
import report2013 from "../../assets/images/2013-global-consular-forum-and-report.png";
import report2015 from "../../assets/images/2015-global-consular-forum-and-report.png";
import report2016 from "../../assets/images/2016-global-consular-forum-and-report.png";

const GlobalConsularForum = () => {
  return (
    <div>
      {AddPageHeader("Global Consular Forum")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>Global Consular Forum</h1>
        </Divider>
        <Image src={logo} alt="Image" width="150" />
        <div className="privacy-terms">
          <br />
          <p>
            The Global Consular Forum is a multilateral initiative fostering
            greater international cooperation and understanding of consular
            policy and operational issues.
          </p>
          <br />
          <p>
            The first forum took place from 3 to 5 September 2013 in Steyning,
            United Kingdom. Senior consular officials hailing from 22 countries,
            along with representatives from the European Commission and
            academia, convened at Wilton Park to discuss contemporary consular
            challenges.
          </p>
        </div>
        <br />
        <a
          href="/pdf/2013-global-consular-forum-report.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <Image src={report2013} alt="report2013" width="40%" />
        </a>
        <br />
        <br />
        <div className="privacy-terms">
          <p>
            The second forum took place from 26 to 28 May 2025 in Cuernavaca,
            Mexico. It drew the participation of 25 countries, centering its
            discussions on seven key themes, covering topics from emergency
            management to safe travel culture.
          </p>
        </div>
        <br />
        <a
          href="/pdf/2015-global-consular-forum-report.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <Image src={report2015} alt="report2015" width="40%" />
        </a>
        <br />
        <br />
        <div className="privacy-terms">
          <p>
            The third forum took place from 25 to 27 October 2016 in Seoul,
            South Korea. The gathering convened officials from 33 countries
            alongside the European External Action Service (EEAS), leading to
            the "Seoul consensus statement on consular cooperation".
          </p>
        </div>
        <br />
        <div>
          <a
            href="/pdf/2016-global-consular-forum-report.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={report2016} alt="report2016" width="40%" />
          </a>
        </div>
        <br />
        <br />
        <div className="privacy-terms">
          <p>
            These documents are the property of the Global Consular Forum. It is
            shared via the Center to facilitate the study and practice of
            consular affairs. Please refer to{" "}
            <a
              target="_blank"
              style={{ color: "black" }}
              href="https://globalconsularforum.com/"
            >
              https://globalconsularforum.com/
            </a>{" "}
            to access the original documentation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GlobalConsularForum;
