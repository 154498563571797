export const ISOLogoTemplate = (isos: string[] = []) => {
  return (
    <div style={{ display: "flex" }}>
      {isos.map((iso: any, i) => (
        <img
          key={i}
          src={"flags/" + iso + ".svg"}
          alt=""
          width="17em"
          style={{ marginRight: "0.7em" }}
        />
      ))}
    </div>
  );
};
