import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

const HonoraryDirectory = () => {
  return (
    <div>
      {AddPageHeader("Who are honorary consuls?")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>Who are honorary consuls?</h1>
        </Divider>
        <div className="privacy-terms">
          Honorary consuls are individuals who serve as representatives of a
          foreign government in a specific country, region, or city voluntarily.
          Unlike career diplomats who are typically citizens of the appointing
          country, honorary consuls are often citizens of the host country. They
          promote economic, cultural, and other ties between their home country
          and the country, region, or city where they are stationed. Honorary
          consuls may assist their fellow citizens with various consular
          services such as issuing visas, providing assistance during
          emergencies, and facilitating trade and investment opportunities. They
          are appointed by the sending country and usually hold their positions
          for a fixed term.
        </div>
      </div>
    </div>
  );
};

export default HonoraryDirectory;
