import React, { Component } from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

class CityComparisonTool extends Component {
  render() {
    return (
      <div>
        {AddPageHeader("City comparison")}
        <div className="reduce-width">
          <Divider align="center">
            <h1>City comparison</h1>
          </Divider>
          <div className="under-construction">Under construction</div>
        </div>
      </div>
    );
  }
}

export default CityComparisonTool;
