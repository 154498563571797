import React from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

const HenryGrahamGreene = () => {
  return (
    <div className="reduce-width">
      {AddPageHeader("Henry Graham Greene ")}
      <Divider align="center">
        <h1>Henry Graham Greene</h1>
      </Divider>
      <div className="privacy-terms">
        <p>
          "The Honorary Consul" is a novel written by British author Henry
          Graham Greene (2 October 1904 – 3 April 1991). Published in 1973, it
          tells the story of Dr. Eduardo Plarr, a half-English, half-Paraguayan
          doctor who becomes involved in a political kidnapping in a small town
          in Argentina. The novel explores themes of political intrigue,
          personal morality, and the complexities of human relationships set
          against the backdrop of South American political unrest.
        </p>
        <p>
          "The Honorary Consul" stands as one of the most prominent fictional
          works centered on an honorary consul.
        </p>
        <p>
          Several editions have been released, featuring contributions from
          various individuals, including forewords. The following excerpt
          provides a glimpse of these editions.
        </p>
        <p>
          This novel by Greene has garnered comments and studies from many,
          including a film. The following excerpt provides a glimpse of these
          works.
        </p>
      </div>
    </div>
  );
};

export default HenryGrahamGreene;
