import MailchimpSubscribe from "react-mailchimp-subscribe";

const url =
  "https://consularaffairs.us8.list-manage.com/subscribe/post?u=348aeb43ab33877744de85b71&amp;id=88dd0a087a&amp;v_id=3372&amp;f_id=00bf0be0f0";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url} />;

// use the render prop and your custom form
const MailChimp = () => {
  return (
    <div class="flex align-items-center justify-content-center">
      <div id="mc_embed_signup">
        <form
          action="https://consularaffairs.us8.list-manage.com/subscribe/post?u=348aeb43ab33877744de85b71&amp;id=88dd0a087a&amp;v_id=3372&amp;f_id=00bf0be0f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <div class="indicates-required">
              <span class="asterisk">*</span> indicates required
            </div>

            <div class="mc-field-group">
              <label for="mce-EMAIL">
                Email Address <span class="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                required=""
                defaultValue=""
              />
            </div>
            <div
              id="mergeRow-gdpr"
              class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
            >
              <div class="content__gdpr">
                <label>Permissions</label>
                <p>
                  Please select all the ways you would like to hear from the
                  Consular Affairs Center:
                </p>
                <fieldset
                  class="mc_fieldset gdprRequired mc-field-group"
                  name="interestgroup_field"
                >
                  <label class="checkbox subfield" for="gdpr42624">
                    <input
                      type="checkbox"
                      id="gdpr_42624"
                      name="gdpr[42624]"
                      class="gdpr"
                      value="Y"
                    />
                    <span>Email</span>
                  </label>
                </fieldset>
                <p>
                  You can unsubscribe at any time by clicking the link in the
                  footer of the emails.
                </p>
              </div>
              <div class="content__gdprLegal">
                <p>
                  We use Mailchimp as our marketing platform. By clicking below
                  to subscribe, you acknowledge that your information will be
                  transferred to Mailchimp for processing.
                  <a href="https://mailchimp.com/legal/terms">
                    Learn more
                  </a>{" "}
                  about Mailchimp's privacy practices.
                </p>
              </div>
            </div>

            <div id="mce-responses" class="clear foot">
              <div
                class="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>

              <div
                class="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>

            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              /* real people should not fill this in and expect good things - do
              not remove this or risk form bot signups */
              <input
                type="text"
                name="b_348aeb43ab33877744de85b71_88dd0a087a"
                tabindex="-1"
                value=""
              />
            </div>

            <div class="optionalParent">
              <div class="clear foot">
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button"
                  value="Subscribe"
                />

                <p style={{ margin: "0px auto" }}>
                  <a
                    href="http://eepurl.com/iAlprc"
                    title="Mailchimp - email marketing made easy and fun"
                  >
                    <span
                      style={{
                        display: "inline-block",
                        backgroundColor: "transparent",
                        borderRadius: "4px",
                      }}
                    >
                      <img
                        class="refferal_badge"
                        src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                        alt="Intuit Mailchimp"
                        style={{
                          width: "220px",
                          height: "40px",
                          display: "flex",
                          padding: "2px 0px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MailChimp;
