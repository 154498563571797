export const ISOLogoTemplate = (iso: string) => {
  return (
    <img
      key={iso}
      src={"flags/" + iso + ".svg"}
      alt=""
      width="17em"
      style={{ marginRight: "0.7em" }}
    />
  );
};
