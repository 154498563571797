import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { ISOLogoTemplate } from "../Common/ISOCountryLogoList";
import { AddPageHeader } from "../Common/Utils";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";

const DidYouKnow = () => {
  const [data, setData] = useState([]);
  const countryService = new CountryService();
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    countryService.getDidYouKnow().then((data) => setData(data));
    initFilters1();
  }, []);

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword search"
          />
        </IconField>
      </div>
    );
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const header1 = renderHeader1();

  const DYNISOLogoTemplate = (rowData) => {
    return <div>{ISOLogoTemplate(rowData.iso)} </div>;
  };

  return (
    <div className="reduce-width">
      {AddPageHeader("Did you know?")}
      <Divider align="center">
        <h1>Did you know?</h1>
      </Divider>
      <DataTable
        value={data}
        responsiveLayout="scroll"
        header={header1}
        filters={filters1}
        filterDisplay="menu"
        style={{}}
      >
        <Column field="date" header="Date" sortable></Column>
        <Column field="title" header="Description" sortable></Column>
        <Column field="iso" header="Country" body={DYNISOLogoTemplate}></Column>
      </DataTable>
    </div>
  );
};

export default DidYouKnow;
