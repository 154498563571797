import React, { Component } from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

class Donate extends Component {
  render() {
    return (
      <div>
        {AddPageHeader("Donate")}
        <div className="reduce-width">
          <Divider align="center">
            <h1>Donate</h1>
          </Divider>
          <div className="privacy-terms">
            <p>
              The Consular Affairs Center requires significant financial
              investment to operate. Your support is crucial in enabling the
              very existence and development of the Center. If you use our
              platforms or read our publications, we kindly invite you to
              contribute to this initiative. Your donation will directly fund
              essential expenses such as hardware and software investments,
              ensuring our continuing commitment to consular affairs and
              diplomatic networks.
            </p>
            <br />
            <p>
              The Center has selected PayPal as the solution for donations.
              Established in 1998, PayPal is a widely used and trusted service.
              Donating through PayPal is fast, secure, and accessible worldwide.
              It offers a user-friendly interface and different payment choices.
              Moreover, PayPal guarantees that your contributions promptly reach
              us and offers transparent records of your donations, ensuring
              peace of mind.
            </p>
            <br />
            <p>Thank you very much.</p>
            <br />
          </div>
        </div>
        <div class="flex justify-content-center mt-4">
          <div class="flex align-items-center mr-5">
            <form
              action="https://www.paypal.com/donate"
              method="post"
              target="_blank"
            >
              <input
                type="hidden"
                name="hosted_button_id"
                value="ZU5H37LZ7PQVW"
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/FR/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypal.com/en_FR/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Donate;
