import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { AddPageHeader } from "../Common/Utils";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getReports().then((data) => setReports(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("Reports")}
      <Divider align="center">
        <h1>Reports</h1>
      </Divider>
      {reports.map((el) => (
        <div key={el.link}>
          <Card>
            <div className="grid">
              <div style={{ textAlign: "left" }}>
                <div className="privacy-terms">
                  <h3>{el.title}</h3>
                  <p>
                    {el.surname !== "" && el.surname + ", "}
                    {el.firstname}{" "}
                    {el.firstnameTranslation !== "" &&
                      el.surnameTranslation !== "" &&
                      " (" +
                        el.surnameTranslation +
                        ", " +
                        el.firstnameTranslation +
                        ")"}
                    {el.firstnameTranslation !== "" &&
                      el.surnameTranslation === "" &&
                      " (" + el.firstnameTranslation + ")"}
                  </p>
                  <br />
                  <ul
                    style={{
                      display: "inline-list-item",
                      listStyle: "square",
                      listStylePosition: "inside",
                    }}
                  >
                    <li>Date: {el.date}</li>
                    <li>
                      Language: {el.language}{" "}
                      <img
                        src={"flags/" + el.iso[0].toLowerCase() + ".svg"}
                        alt=""
                        width="17em"
                        style={{ marginRight: "0.7em" }}
                      />
                    </li>
                    <li>
                      Report:{" "}
                      <a
                        href="/pdf/20240619-toustou-macha.pdf"
                        target="_blank"
                        style={{ color: "black", marginRight: "1rem" }}
                      >
                        {el.link}
                      </a>
                    </li>
                  </ul>
                  <br />
                  <p>
                    <b>Abstract:</b> {el.abstract}
                  </p>
                  <br />
                  <p>
                    <b>Title in English:</b> {el.titleEnglish}
                  </p>
                  <br />
                  <p>
                    <b>Abstract in English:</b> {el.abstractEnglish}
                  </p>
                </div>
              </div>
            </div>
            <br />
          </Card>
          <br />
        </div>
      ))}
    </div>
  );
};

export default Reports;
