import { Image } from "primereact/image";
import vienna1961 from "../../assets/images/1961-vienna-convention.jpg";
import vienna1963 from "../../assets/images/1963-vienna-convention.jpg";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";

const ViennaConventions = () => {
  return (
    <div className="reduce-width">
      {AddPageHeader("1961 and 1963 Vienna conventions")}
      <Divider align="center">
        <h1>1961 and 1963 Vienna conventions</h1>
      </Divider>
      <div className="privacy-terms">
        <h3>1961 Vienna Convention on Diplomatic Relations</h3>
        <br />
        <p>
          The Convention was adopted on 14 April 1961 by the United Nations
          Conference on Diplomatic Intercourse and Immunities held at the Neue
          Hofburg in Vienna, Austria, from 2 March to 14 April 1961. The
          Conference also adopted the Optional Protocol concerning the
          Acquisition of Nationality, the Optional Protocol concerning the
          Compulsory Settlement of Disputes, the Final Act, and four resolutions
          annexed to that Act. The Convention and two Protocols were deposited
          with the Secretary-General of the United Nations. The Final Act, by
          unanimous decision of the Conference, was deposited in the archives of
          the Federal Ministry for Foreign Affairs of Austria.
        </p>
        <br />
      </div>
      <a
        href="/pdf/1961-vienna-convention-on-diplomatic-relations-document.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <Image src={vienna1961} alt="vienna1961" width="40%" />
      </a>
      <br />
      <div className="privacy-terms">
        <br />
        <h3>1963 Vienna Convention on Consular Relations</h3>
        <br />
        <p>
          The Convention was adopted on 22 April 1963 by the United Nations
          Conference on Consular Relations held at the Neue Hofburg in Vienna,
          Austria, from 4 March to 22 April 1963. The Conference also adopted
          the Optional Protocol concerning the Acquisition of Nationality, the
          Optional Protocol concerning the Compulsory Settlement of Disputes,
          the Final Act, and three resolutions annexed to that Act. The
          Convention and the two Protocols were deposited with the
          Secretary-General of the United Nations. The Final Act, by unanimous
          decision of the Conference, was deposited in the archives of the
          Federal Ministry for Foreign Affairs of Austria.
        </p>
        <br />
      </div>
      <a
        href="/pdf/1963-vienna-convention-on-consular-relations-document.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <Image src={vienna1963} alt="vienna1961" width="40%" />
      </a>
      <br />
      <br />
      <div className="privacy-terms">
        These documents are the property of the United Nations. They are shared
        via the Center to facilitate the study and practice of consular affairs.
        Please refer to{" "}
        <a
          href="https://www.un.org/"
          target="_blank"
          style={{ color: "black" }}
        >
          https://www.un.org/
        </a>{" "}
        to access the original documentation.
      </div>
    </div>
  );
};

export default ViennaConventions;
