import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { AddPageHeader } from "../Common/Utils";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";

const Notice = () => {
  const [notice, setNotice] = useState([]);
  const countryService = new CountryService();
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    countryService.getNotice().then((data) => setNotice(data));
    initFilters1();
  }, []);

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword search"
          />
        </IconField>
      </div>
    );
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const descriptionTemplate = (rowData) => {
    return <div style={{ textAlign: "justify" }}>{rowData.text}</div>;
  };

  const header1 = renderHeader1();

  return (
    <div className="reduce-width">
      {AddPageHeader("Notice")}
      <Divider align="center">
        <h1>Notice</h1>
      </Divider>
      <DataTable
        value={notice}
        responsiveLayout="scroll"
        header={header1}
        filters={filters1}
        filterDisplay="menu"
        paginator={true}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} to {last} of {totalRecords} results"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column field="date" header="Date" sortable></Column>
        <Column field="title" header="Title" sortable></Column>
        <Column
          field="text"
          header="Description"
          body={descriptionTemplate}
        ></Column>
      </DataTable>
    </div>
  );
};

export default Notice;
