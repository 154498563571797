import React, { useState, useEffect } from "react";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import CountrySearchStatistics from "../WorldMap/CountrySearchStatistics";
import OfficialDirectoryTable from "../Common/OfficialDirectoryTable";
import HonoraryDirectoryTable from "../Common/HonoraryDirectoryTable";
import CountryRankingTable from "../Common/CountryRankingTable";
import CityRankingTable from "../Common/CityRankingTable";
import LawRegulationsTable from "../Common/LawRegulationsTable";
import BibliographyTable from "../Common/BibliographyTable";
import NewsTable from "../Common/NewsTable";
import { CountryService } from "../../services/CountryService";
import { Link } from "react-router-dom";
import { AddPageHeader } from "../Common/Utils";

const CountryProfiles = () => {
  const [searchCountries, setSearchCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState(null);
  const [news, setNews] = useState([]);
  const [officialdirectory, setOfficialDirectory] = useState([]);
  const [consularDirectory, setHonoraryCorpsDirectory] = useState([]);
  const [laws, setLaws] = useState([]);
  const [bibliography, setBibliography] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService
      .getAllCountries()
      .then((data) => buildCountriesList(data))
      .then((data) => setSearchCountries(data));
  }, []);

  function buildCountriesList(list) {
    return list.map((el) => {
      const container = {};
      container.code = el.code.toLowerCase();
      container.name = "(" + el.code + ") " + el.name;
      return container;
    });
  }

  function selectCountry(e) {
    if (!e.value) {
      setCountry(null);
      return;
    }
    setCountry(e.value);

    countryService
      .getOfficialDirectory(-1, e.value.code)
      .then((data) => setOfficialDirectory(data));
    countryService
      .getCountriesStatistics(-1, e.value.code)
      .then((data) => setCountries(data));
    countryService
      .getCitiesStatistics(-1, e.value.code)
      .then((data) => setCities(data));

    countryService
      .getBibliography(-1, e.value.code)
      .then((data) => setBibliography(data));
    countryService.getNews(-1, e.value.code).then((data) => setNews(data));
    countryService
      .getHonoraryCorpsDirectory(-1, e.value.code)
      .then((data) => setHonoraryCorpsDirectory(data));
    countryService.getLaws(e.value.code).then((data) => setLaws(data));
  }

  return (
    <div>
      {AddPageHeader("Country profiles")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>{!country && <div> Country profiles</div>}</h1>
          <h1>{country && <div> Country profile: {country.name}</div>}</h1>
        </Divider>
        <Card style={{ marginTop: "2em" }}>
          <CountrySearchStatistics
            countries={searchCountries}
            action={selectCountry}
          />
        </Card>
        {country && (
          <div>
            <Card style={{ marginTop: "1em" }}>
              <h3 className="section-title">
                Diplomatic directory{" "}
                <Link to="/diplomatic-directory" style={{ color: "black" }}>
                  <i className="pi pi-external-link"></i>
                </Link>
              </h3>
              <OfficialDirectoryTable
                data={officialdirectory}
                longDescription
              />
              <br />
              <h3 className="section-title">
                City ranking{" "}
                <Link to="/city-ranking" style={{ color: "black" }}>
                  <i className="pi pi-external-link"></i>
                </Link>
              </h3>
              <CityRankingTable data={cities} longDescription /> <br />
              <h3 className="section-title">
                Country rankings{" "}
                <Link to="/country-rankings" style={{ color: "black" }}>
                  <i className="pi pi-external-link"></i>
                </Link>
              </h3>
              <CountryRankingTable data={countries} longDescription />
              <br />
              <h3 className="section-title">
                Laws, regulations, and policies{" "}
                <Link to="/laws" style={{ color: "black" }}>
                  <i className="pi pi-external-link"></i>
                </Link>
              </h3>
              <LawRegulationsTable data={laws} longDescription />
              <br />
              <h3 className="section-title">
                Bibliography{" "}
                <Link to="/bibliography" style={{ color: "black" }}>
                  <i className="pi pi-external-link"></i>
                </Link>
              </h3>
              <BibliographyTable data={bibliography} longDescription />
              <br />
              <h3 className="section-title">
                Daily press review{" "}
                <Link to="/daily-press-review" style={{ color: "black" }}>
                  <i className="pi pi-external-link"></i>
                </Link>
              </h3>
              <NewsTable data={news} longDescription />
              <br />
              <h3 className="section-title">
                Honorary corps directory{" "}
                <Link to="/honorary-corps-directory" style={{ color: "black" }}>
                  <i className="pi pi-external-link"></i>
                </Link>
              </h3>
              <HonoraryDirectoryTable
                data={consularDirectory}
                longDescription
              />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountryProfiles;
