import React, { useState, useEffect } from "react";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
import { CountryService } from "../../services/CountryService";
import HonoraryDirectoryTable from "../Common/HonoraryDirectoryTable";
import { AddPageHeader } from "../Common/Utils";

const HonoraryCorpsDirectory = () => {
  const [honoraryCorpsDirectory, setHonoraryCorpsDirectory] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService
      .getHonoraryCorpsDirectory()
      .then((data) => setHonoraryCorpsDirectory(data));
  }, []);

  return (
    <div>
      {AddPageHeader("Honorary corps directory")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>Honorary corps directory</h1>
        </Divider>
        <div className="privacy-terms">
          The directory consolidates public sources of information on worldwide
          career and honorary consular corps. Are you an organization
          representing career and/or honorary consuls? Would you like the
          directory to be updated? Please feel free to{" "}
          <Link
            to="/contact"
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            contact
          </Link>{" "}
          the Consular Affairs Center.
        </div>
        <HonoraryDirectoryTable
          data={honoraryCorpsDirectory}
          searchEnabled
          longDescription
        />
      </div>
    </div>
  );
};

export default HonoraryCorpsDirectory;
