var config = require("../config/config.js");
var backend_url = config.backend_url;

export class CountryService {
  // Fetch all countries.
  async getAllCountries() {
    const res = await fetch(backend_url + "/api/v1/countries?lang=en");
    const d = await res.json();
    return d.embassies;
  }

  // Fetch all cities.
  async getAllCities() {
    const res = await fetch(backend_url + "/api/v2/cities?lang=en");
    const d = await res.json();
    return d.cities;
  }

  // Fetch specific city statistics.
  async getCityStatistics(code) {
    const res = await fetch(
      backend_url + "/api/v2/statistics/city/" + code + "?lang=en"
    );
    return res.json();
  }

  // Fetch all country oversea statistics.
  async getCountriesStatistics(limit = -1, code = "") {
    const res = await fetch(
      backend_url +
        "/api/v2/statistics/countries?lang=en&limit=" +
        limit +
        "&country=" +
        code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch all country domestically statistics.
  async getCountryDomesticallyStatistics(limit = -1, code = "") {
    const res = await fetch(
      backend_url +
        "/api/v2/statistics/countries-domestically?lang=en&limit=" +
        limit +
        "&country=" +
        code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch specific country statistics.
  async getCountryStatistics(code) {
    const res = await fetch(
      backend_url + "/api/v2/statistics/country/" + code + "?lang=en"
    );
    return res.json();
  }

  // Fetch all city statistics.
  async getCitiesStatistics(limit = -1, code = "") {
    const res = await fetch(
      backend_url +
        "/api/v2/statistics/cities?lang=en&limit=" +
        limit +
        "&country=" +
        code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch all the news.
  async getNews(limit = -1, code = "") {
    const res = await fetch(
      backend_url + "/api/v2/news?limit=" + limit + "&country=" + code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch all the honorary news.
  async getHonoraryNews(limit = -1, code = "") {
    const res = await fetch(
      backend_url + "/api/v2/honorary-news?limit=" + limit + "&country=" + code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch all the honorary bibliography.
  async getHonoraryBibliography(limit = -1, code = "") {
    const res = await fetch(
      backend_url +
        "/api/v2/honorary-bibliography?limit=" +
        limit +
        "&country=" +
        code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch all the did you know.
  async getDidYouKnow() {
    const res = await fetch(backend_url + "/api/v2/did-you-know");
    const d = await res.json();
    return d.results;
  }

  // Fetch all the links.
  async getLinks() {
    const res = await fetch(backend_url + "/api/v2/interesting-links");
    const d = await res.json();
    return d.results;
  }

  // Fetch all the official directory.
  async getOfficialDirectory(limit = -1, code = "") {
    const res = await fetch(
      backend_url +
        "/api/v2/official-directory?limit=" +
        limit +
        "&country=" +
        code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch the glossary.
  async getGlossary() {
    const res = await fetch(backend_url + "/api/v2/glossary");
    const d = await res.json();
    return d.results;
  }

  // Fetch the reports.
  async getReports() {
    const res = await fetch(backend_url + "/api/v2/reports");
    const d = await res.json();
    return d.results;
  }

  // Fetch the jobs.
  async getJobs(limit = -1) {
    const res = await fetch(backend_url + "/api/v2/jobs?limit=" + limit);
    const d = await res.json();
    return d.results;
  }

  // Fetch all the laws.
  async getLaws(limit = -1, code = "") {
    const res = await fetch(
      backend_url + "/api/v2/laws?limit=" + limit + "&country=" + code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch the Honorary corps directory.
  async getHonoraryCorpsDirectory(limit = -1, code = "") {
    const res = await fetch(
      backend_url +
        "/api/v2/honorary-corps-directory?limit=" +
        limit +
        "&country=" +
        code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch all the bibliography.
  async getBibliography(limit = -1, code = "") {
    const res = await fetch(
      backend_url + "/api/v2/bibliography?limit=" + limit + "&country=" + code
    );
    const d = await res.json();
    return d.results;
  }

  // Fetch all the authors.
  async getAuthors() {
    const res = await fetch(backend_url + "/api/v2/authors");
    const d = await res.json();
    return d.results;
  }

  // Fetch all the correspondents.
  async getCorrespondents() {
    const res = await fetch(backend_url + "/api/v2/correspondents");
    const d = await res.json();
    return d.results;
  }

  // Fetch all the research fellows.
  async getResearchFellows() {
    const res = await fetch(backend_url + "/api/v2/research-fellows");
    const d = await res.json();
    return d.results;
  }

  // Fetch all the partners.
  async getPartners() {
    const res = await fetch(backend_url + "/api/v2/partners");
    const d = await res.json();
    return d.results;
  }

  // Fetch all the notice.
  async getNotice(limit) {
    const res = await fetch(backend_url + "/api/v2/notice?limit=" + limit);
    const d = await res.json();
    return d.results;
  }

  // Fetch all the info.
  async getInfo() {
    const res = await fetch(backend_url + "/api/v2/info");
    const d = await res.json();
    return d;
  }

  // Send update form.
  async sendUpdateForm(data) {
    const res = await fetch(backend_url + "/api/v2/update", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  }

  // Send contact form.
  async sendContactForm(data) {
    const res = await fetch(backend_url + "/api/v2/contact", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  }
}
