import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { ISOLogoTemplate } from "./ISOCountryLogoList";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";

const BibliographyTable = ({ data, searchEnabled, longDescription }) => {
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [expandedRows, setExpandedRows] = useState(null);

  useEffect(() => {
    initFilters1();
  }, []);

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  const allowExpansion = (rowData) => {
    return rowData.orders && rowData.orders.length > 0;
  };

  const renderHeader1 = () => {
    if (searchEnabled) {
      return (
        <div className="flex justify-content-end">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Keyword search"
            />
          </IconField>
        </div>
      );
    }
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const header1 = renderHeader1();

  const PubISOLogoTemplate = (rowData) => {
    return <div>{ISOLogoTemplate(rowData.iso)} </div>;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div>
        <p>{"Publication Title: " + data["publication-title"]}</p>
        <p>{"Volume: " + data.volume}</p>
        <p>{"Issue: " + data.issue}</p>
        <p>{"Pages: " + data.pages}</p>
        <p>{"Series: " + data.series}</p>
        <p>{"Editor: " + data.editor}</p>
        <p>{"Edition: " + data.edition}</p>
        <p>{"Publisher: " + data.publisher}</p>
        <p>{"Place: " + data.place}</p>
        <p>{"DOI: " + data.doi}</p>
        <p>{"ISSN: " + data.issn}</p>
        <p>{"ISBN: " + data.isbn}</p>
        <p>{"Other Type: " + data["other-type"]}</p>
        <p>{"Conference Name: " + data["conference-name"]}</p>
        <p>{"Number: " + data.number}</p>
        <p>{"Session: " + data.session}</p>
        <p>{"Extra: " + data.extra}</p>
      </div>
    );
  };

  const summaryTemplate = (data) => {
    return <div style={{ textAlign: "justify" }}>{data.summary}</div>;
  };

  return (
    <DataTable
      value={data}
      responsiveLayout="scroll"
      header={header1}
      filters={filters1}
      filterDisplay="menu"
      paginator={longDescription}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="{first} to {last} of {totalRecords} results"
      rows={5}
      rowsPerPageOptions={[10, 20, 50, 100]}
      expandedRows={expandedRows}
      onRowToggle={(e) => setExpandedRows(e.data)}
      rowExpansionTemplate={rowExpansionTemplate}
    >
      <Column expander={allowExpansion} style={{ width: "5rem" }} />
      <Column field="year" header="Year" sortable></Column>
      <Column field="title" header="Title" sortable></Column>
      <Column field="author" header="Author" sortable></Column>
      {longDescription && <Column field="type" header="Type" sortable></Column>}
      {longDescription && (
        <Column
          field="summary"
          header="Summary"
          body={summaryTemplate}
          sortable
        ></Column>
      )}
      {longDescription && (
        <Column field="iso" header="Country" body={PubISOLogoTemplate}></Column>
      )}
    </DataTable>
  );
};

export default BibliographyTable;
