import React from "react";
import { Carousel } from "primereact/carousel";
import FacebookContainer from "./FacebookContainer";
import TwitterContainer from "./TwitterContainer";

const SocialNetworksCarousel = () => {
  const socialNetworks = [
    {
      type: "facebook",
    },
    {
      type: "twitter",
    },
  ];

  const socialNetworksTemplate = (data) => {
    return (
      <div>
        {data.type === "facebook" && <FacebookContainer />}
        {data.type === "twitter" && <TwitterContainer />}
      </div>
    );
  };

  return (
    <div className="card">
      <Carousel
        value={socialNetworks}
        numVisible={1}
        numScroll={1}
        itemTemplate={socialNetworksTemplate}
      />
    </div>
  );
};

export default SocialNetworksCarousel;
