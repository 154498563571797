import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import NewsTable from "../Common/NewsTable";
import { Link } from "react-router-dom";
import { AddPageHeader } from "../Common/Utils";

const News = () => {
  const [news, setNews] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getNews().then((data) => setNews(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("News")}
      <Divider align="center">
        <h1>Daily press review</h1>
      </Divider>
      <div className="privacy-terms">
        The daily press review aggregates the latest major news on consular
        affairs and diplomatic networks from both global and local press
        sources. The review is also accessible via the Consular Affairs Center’s
        social networks and{" "}
        <Link
          to="/weekly-newsletter"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          weekly newsletter
        </Link>
        . Are you a recognized press body? Would you like to highlight specific
        news? Please feel free to{" "}
        <Link
          to="/contact"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          contact
        </Link>{" "}
        the Center.
      </div>
      <br />
      <NewsTable data={news} searchEnabled longDescription />
    </div>
  );
};

export default News;
