import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";
import NewsTable from "../Common/NewsTable";

const HonoraryNews = () => {
  const [news, setNews] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getHonoraryNews().then((data) => setNews(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("Honorary news")}
      <Divider align="center">
        <h1>Honorary news</h1>
      </Divider>
      <div className="privacy-terms">
        This page aggregates specific news pertaining to honorary consuls from
        both global and local press sources; it does not provide updates on
        routine matters such as appointments or visits. The latest major news on
        consular affairs and diplomatic networks are accessible via the Consular
        Affairs Center’s{" "}
        <Link
          to="/daily-press-review"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          daily press review
        </Link>
        , social networks, and{" "}
        <Link
          to="/weekly-newsletter"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          weekly newsletter
        </Link>
        . Are you a recognized press body or an honorary consul? Would you like
        to highlight specific news? Please feel free to{" "}
        <Link
          to="/contact"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          contact
        </Link>{" "}
        the Center.
      </div>
      <br />
      <NewsTable data={news} searchEnabled longDescription />
    </div>
  );
};

export default HonoraryNews;
