import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import "./css/mobile_index.css";
import Favicon from "react-favicon";
import App from "./App";
import logo from "./assets/images/CAC-logo.png";
import { Helmet } from "react-helmet";
import { ConsularAffairsTitle } from "./components/Common/Constants";

ReactDOM.render(
  <div>
    <Favicon url={logo} />
    <React.StrictMode>
      <Helmet>
        <title>{ConsularAffairsTitle}</title>
        <meta name="description" content="Consular Affairs Center" />
        <meta
          property="og:image"
          content="https://consular/blog/wp-content/uploads/2019/12/fb-how-to-become-an-seo-expert.png"
        />
      </Helmet>
      <App />
    </React.StrictMode>
  </div>,
  document.getElementById("root")
);
