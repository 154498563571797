import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ReactTooltip from "react-tooltip";

const CountryRankingTable = ({ data, longDescription }) => {
  const countryTemplate = (rowData) => {
    return (
      <div style={{ display: "flex" }}>
        <img
          src={"flags/" + rowData.code.toLowerCase() + ".svg"}
          alt=""
          width="17em"
          style={{ marginRight: "0.7em" }}
        />
        {rowData.name}
      </div>
    );
  };

  const isoTemplate = (rowData) => {
    return rowData.code.toUpperCase();
  };

  const missionTemplate = (rowData) => {
    return rowData.missions + "/9";
  };

  const populationTemplate = () => {
    return (
      <div>
        Population (thousands)
        <i
          data-tip="Source: World Bank (June 14, 2024)."
          className="pi pi-info-circle ml-1"
        ></i>
        <ReactTooltip />
      </div>
    );
  };

  const gdpTemplate = () => {
    return (
      <div>
        GDP (billions; current USD)
        <i
          data-tip="Source: World Bank (June 14, 2024)."
          className="pi pi-info-circle ml-1 "
        ></i>
        <ReactTooltip />
      </div>
    );
  };

  return (
    <DataTable
      value={data}
      responsiveLayout="scroll"
      paginator={longDescription}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="{first} to {last} of {totalRecords} results"
      rows={10}
      rowsPerPageOptions={[10, 20, 50, 100]}
    >
      <Column field="code" header="ISO" body={isoTemplate} sortable></Column>
      <Column
        field="name"
        header="Country"
        body={countryTemplate}
        sortable
      ></Column>
      <Column field="population" header={populationTemplate} sortable></Column>
      <Column field="gdp" header={gdpTemplate} sortable></Column>
      <Column
        field="embassies"
        header="Embassies and high commissions"
        sortable
      ></Column>
      <Column field="consulates" header="Consulates" sortable></Column>
      <Column field="others" header="Other posts" sortable></Column>
      <Column field="totalposts" header="Total posts" sortable></Column>
      <Column
        field="embassiesranking"
        header="Ranking (embassies and high commissions)"
        sortable
      ></Column>
      <Column
        field="totalpostsranking"
        header="Ranking (total posts)"
        sortable
      ></Column>
      <Column
        field="missions"
        header="Permanent missions"
        body={missionTemplate}
        sortable
      ></Column>
    </DataTable>
  );
};

export default CountryRankingTable;
