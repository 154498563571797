import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { SocialNetworksTemplate, LinkTemplate } from "./SocialNetworkHelpers";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";

const OfficialDirectoryTable = ({ data, searchEnabled, longDescription }) => {
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    initFilters1();
  }, []);

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  const renderHeader1 = () => {
    if (searchEnabled) {
      return (
        <div className="flex justify-content-end">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Keyword search"
            />
          </IconField>
        </div>
      );
    }
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const header1 = renderHeader1();

  const countryTemplate = (rowData) => {
    return (
      <div style={{ display: "flex" }}>
        <img
          src={"flags/" + rowData.code.toLowerCase() + ".svg"}
          alt=""
          width="17em"
          style={{ marginRight: "0.7em" }}
        />
        {rowData.country}
      </div>
    );
  };

  const denominationTemplate = (rowData) => {
    return <div style={{ display: "flex" }}>{rowData.denomination}</div>;
  };

  return (
    <DataTable
      value={data}
      responsiveLayout="scroll"
      header={header1}
      filters={filters1}
      filterDisplay="menu"
      paginator={longDescription}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="{first} to {last} of {totalRecords} results"
      rows={10}
      rowsPerPageOptions={[10, 20, 50, 100]}
    >
      <Column field="code" header="ISO" sortable></Column>
      <Column
        field="country"
        header="Country"
        body={countryTemplate}
        sortable
      ></Column>
      <Column
        field="denomination"
        header="Denomination"
        body={denominationTemplate}
        sortable
      ></Column>
      {longDescription && (
        <Column field="website" header="Website" body={LinkTemplate}></Column>
      )}
      {longDescription && (
        <Column
          field="socialnetworks"
          header="Social networks"
          body={SocialNetworksTemplate}
        ></Column>
      )}
    </DataTable>
  );
};

export default OfficialDirectoryTable;
