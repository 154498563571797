import React, { useState, useEffect } from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";
import { Link } from "react-router-dom";
import { CountryService } from "../../services/CountryService";
import BibliographyTable from "../Common/BibliographyTable";

const Bibliography = () => {
  const [bibliography, setBibliography] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getBibliography(0).then((data) => setBibliography(data));
  }, []);

  return (
    <div>
      {AddPageHeader("Bibliography")}
      <div className="reduce-width">
        <Divider align="center">
          <h1>Bibliography</h1>
        </Divider>
        <div className="privacy-terms">
          Have you published a book, a book chapter, a conference paper, a
          journal article, a magazine article, a preprint, a report, or a thesis
          pertaining to consular affairs and/or diplomatic networks? Please feel
          free to{" "}
          <Link
            to="/contact"
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            contact
          </Link>{" "}
          the Consular Affairs Center to have your work documented.
        </div>
        <BibliographyTable
          data={bibliography}
          longDescription
          searchEnabled={true}
        />
      </div>
    </div>
  );
};

export default Bibliography;
