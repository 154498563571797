import React, { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { CountryService } from "../../services/CountryService";
import { AddPageHeader } from "../Common/Utils";

const countryService = new CountryService();

const Update = () => {
  let navigate = useNavigate();

  const toast = useRef(null);
  const defaultValues = {
    updateType: "",
    postType: "",
    ofWhichCountry: "",
    whereCountry: "",
    whereCity: "",
    description: "",
    accept: false,
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Message sent.",
      detail: "Thank you.",
    });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    // // Fill the JSON request body.
    var req = {
      type: data.updateType.name,
      posttype: data.postType,
      ofwhichcountry: data.ofWhichCountry,
      wherecountry: data.whereCountry,
      wherecity: data.whereCity,
      text: data.description,
    };
    countryService.sendUpdateForm(req);
    showSuccess();
    setTimeout(() => {
      navigate("/");
    }, 2000);

    reset();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const updateType = [
    { name: "Opening" },
    { name: "Suspension" },
    { name: "Closure" },
    { name: "Other" },
  ];

  return (
    <div>
      {AddPageHeader("Update")}
      <Toast ref={toast} position="top-center" />

      <div className="reduce-width">
        <Divider align="center">
          <h1>Update</h1>
        </Divider>

        <div className="flex justify-content-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-fluid mt-2 w-full md:w-8"
            style={{ textAlign: "left" }}
          >
            <div className="bottom-margin">
              <span className="p-float-label">
                <Controller
                  name="updateType"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                      options={updateType}
                      value={field.value}
                      optionLabel="name"
                    />
                  )}
                />
                <label
                  htmlFor="updateType"
                  className={classNames({ "p-error": errors.name })}
                >
                  Update type (opening, suspension, closure, other)
                </label>
              </span>
              {getFormErrorMessage("updateType")}
            </div>
            <div className="bottom-margin">
              <span className="p-float-label">
                <Controller
                  name="postType"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="postType"
                  className={classNames({ "p-error": errors.name })}
                >
                  Post type (embassy, high commission, consulate, permanent
                  mission, or other)
                </label>
              </span>
              {getFormErrorMessage("postType")}
            </div>
            <div className="bottom-margin">
              <span className="p-float-label">
                <Controller
                  name="ofWhichCountry"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="ofWhichCountry"
                  className={classNames({ "p-error": errors.name })}
                >
                  Of which country?
                </label>
              </span>
              {getFormErrorMessage("ofWhichCountry")}
            </div>
            <div className="bottom-margin">
              <span className="p-float-label">
                <Controller
                  name="whereCountry"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="whereCountry"
                  className={classNames({ "p-error": errors.name })}
                >
                  In which country?
                </label>
              </span>
              {getFormErrorMessage("whereCountry")}
            </div>
            <div className="bottom-margin">
              <span className="p-float-label">
                <Controller
                  name="whereCity"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="whereCity"
                  className={classNames({ "p-error": errors.name })}
                >
                  In which city?
                </label>
              </span>
              {getFormErrorMessage("whereCity")}
            </div>
            <div>
              <span className="p-float-label">
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: "Description is required." }}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={5}
                      autoFocus
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                  )}
                />
                <label
                  htmlFor="description"
                  className={classNames({ "p-error": errors.name })}
                >
                  Description*
                </label>
              </span>
              {getFormErrorMessage("description")}
            </div>
            <div className="flex justify-content-center">
              <Button
                type="submit"
                label="Send"
                className="mt-2 w-full md:w-5"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Update;
